import React, {useEffect, useState} from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation } from 'react-router-dom';
import { jwtDecode } from 'jwt-decode'; // Usa questa forma se l'importazione nominata è necessaria
import PremiumHomepage from './PremiumHomepage';
import Homepage from './Homepage';
import LoginPage from './LoginPage';
import RegisterPage from './RegisterPage';
import ProductsPage from './ProductsPage';
import CheckoutPage from './CheckoutPage';
import SuccessPayment from './SuccessPayment';
import TermsConditionsPage from './TermsConditionsPage';
import Errore from './ErrorPayment';
import Match from './Match';
import Footer from './Footer';
import Header from './Header';
import NotFoundPage from './NotFoundPage';
import AdminDashboard from "./AdminDashboard";
import FreeLoggedHomePage from "./FreeLoggedHomePage";
import {FloatingWhatsApp} from "react-floating-whatsapp";
import CookieBanner from "./CookieBanner";
import CookieConditionsPage from "./CookieConditionsPage";
import labels from "./labels";

// Variabile per salvare le posizioni dello scroll
const scrollPositions = {};

const ScrollRestoration = ({ children }) => {
    const location = useLocation();

    useEffect(() => {
        // Salva la posizione dello scroll prima di cambiare pagina
        return () => {
            scrollPositions[location.pathname] = window.scrollY;
        };
    }, [location.pathname]);

    useEffect(() => {
        // Ripristina la posizione dello scroll quando si ritorna a una pagina
        const savedPosition = scrollPositions[location.pathname] || 0;
        window.scrollTo(0, savedPosition);
    }, [location.pathname]);

    return <>{children}</>;
};

const checkSessionValidity = () => {
    const sessionCookie = document.cookie
        .split('; ')
        .find(row => row.startsWith('sessionwebauth='));

    if (!sessionCookie) {
        console.log("No session cookie found.");
        return { isLogged: false, isFree: true, isSubscribed: false, isAdmin: false };
    }

    const token = sessionCookie.split('=')[1];
    try {
        const decoded = jwtDecode(token);
        const { expirationDate, isAdmin } = decoded;
        console.log('jwt: '+decoded)
        return {
            isLogged: true,
            isFree: new Date(expirationDate) < new Date(),
            isSubscribed: new Date(expirationDate) > new Date(),
            isAdmin: isAdmin === 1
        };
    } catch (error) {
        console.error("Error decoding token:", error);
        return { isLogged: false, isFree: true, isSubscribed: false, isAdmin: false };
    }
};

const AppContent = () => {
    const location = useLocation();
    const { isLogged, isFree, isSubscribed, isAdmin } = checkSessionValidity();

    const [cookieAccepted, setCookieAccepted] = useState(false);
    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);


    useEffect(() => {
        // Recupera la lingua salvata o usa quella del browser
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        // Aggiorna le etichette quando cambia la lingua salvata dal Footer
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    useEffect(() => {
        const savedPreferences = localStorage.getItem('cookie-preferences');
        if (savedPreferences) {
            const preferences = JSON.parse(savedPreferences);
            if (preferences.essential) {
                setCookieAccepted(true); // Verifica se almeno i cookie essenziali sono stati accettati
            }
        }
    }, []);
    return (
        <>
            {/* Header visibile sempre tranne che per "/" se isValid è false */}
            {location.pathname !== '/' || isSubscribed || (isLogged && isFree) ? <Header /> : null}
            <CookieBanner />
            <FloatingWhatsApp   accountName={'GizzoBet'}
                                phoneNumber={"+393922549252"}
                                avatar={"https://gizzobet.com/favicon.png"}
                                placeholder={currentLabels.placeholder}
                                chatMessage={currentLabels.chatMessage}
                                statusMessage={currentLabels.statusMessage}
            />
            <Routes>
                <Route path="/" element={isSubscribed ? <PremiumHomepage /> : (isLogged && isFree) ? <FreeLoggedHomePage /> : <Homepage />} />
                <Route path="/login" element={isSubscribed ? <Navigate to="/" /> : (cookieAccepted) ? <LoginPage /> : <CookieConditionsPage />} />
                <Route path="/register" element={isSubscribed ? <Navigate to="/" /> : (cookieAccepted) ? <RegisterPage /> : <CookieConditionsPage />} />
                <Route path="/products" element={isSubscribed ? <Navigate to="/" /> : <ProductsPage />} />
                <Route path="/admin-page" element={!isAdmin ? <Navigate to="/" /> : <AdminDashboard />} />
                <Route path="/checkout" element={<CheckoutPage />} />
                <Route path="/success" element={<SuccessPayment />} />
                <Route path="/terms-and-conditions" element={<TermsConditionsPage />} />
                <Route path="/cookie-policy" element={<CookieConditionsPage />} />
                <Route path="/error" element={<Errore />} />
                <Route path="/match" element={<Match />} />
                {/*<Route path="/home" element={<Home />} />*/}
                <Route path="*" element={<NotFoundPage />} />
            </Routes>
            <Footer />
        </>
    );
};

const App = () => {
    return (
        <Router>
            <ScrollRestoration>
                <AppContent />
            </ScrollRestoration>
        </Router>
    );
};

export default App;
