import React, { useEffect, useState } from 'react';
import labels from "./labels";

const CookieConditionsPage = () => {
    const [language, setLanguage] = useState('en');
    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setLanguage(initialLanguage);
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    useEffect(() => {
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage && savedLanguage !== language) {
            setLanguage(savedLanguage);
            setCurrentLabels(labels[savedLanguage]);
        }
    }, [language]);

    return (
        <div className="min-h-screen bg-gray-50 flex items-center justify-center py-10 px-4 sm:px-6 lg:px-8">
            {currentLabels.lang === 'ro' ? (
                <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8">
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">Politica privind Cookie-urile pentru GizzoBet.com</h1>
                    <p className="text-gray-700 mb-4">
                        Această pagină explică modul în care GizzoBet.com utilizează cookie-uri și alte tehnologii similare pentru a îmbunătăți experiența utilizatorilor.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">1. Ce sunt cookie-urile?</h2>
                    <p className="text-gray-700 mb-4">
                        Cookie-urile sunt fișiere mici de text stocate pe dispozitivul dvs. atunci când vizitați un site web. Acestea sunt utilizate pentru a reține preferințele și a îmbunătăți funcționalitatea site-ului.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">2. Cum folosim cookie-urile?</h2>
                    <p className="text-gray-700 mb-4">
                        Utilizăm cookie-uri pentru a analiza traficul site-ului, a personaliza conținutul și a îmbunătăți experiența utilizatorilor.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">3. Tipuri de cookie-uri utilizate</h2>
                    <ul className="list-inside list-disc text-gray-700 mb-4">
                        <li><strong>Cookie-uri esențiale:</strong> Necesare pentru funcționalitatea de bază a site-ului și securitate. Acestea nu pot fi dezactivate.</li>
                        <li><strong>Cookie-uri de analiză:</strong> Ne ajută să înțelegem modul în care vizitatorii interacționează cu site-ul, contribuind la îmbunătățirea serviciilor noastre.</li>
                        <li><strong>Cookie-uri de marketing:</strong> Permite furnizarea de reclame și conținut personalizat pe baza comportamentului dvs. de navigare.</li>
                        <li><strong>Cookie-uri de preferințe:</strong> Permit site-ului să rețină preferințele dvs. și să personalizeze experiența utilizatorului.</li>
                    </ul>
                </div>
            ) : (
                <div className="max-w-3xl w-full bg-white rounded-lg shadow-lg p-8">
                    <h1 className="text-3xl font-bold text-gray-900 mb-6">Cookie Policy for GizzoBet.com</h1>
                    <p className="text-gray-700 mb-4">
                        This page explains how GizzoBet.com uses cookies and similar technologies to enhance user experience.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">1. What are cookies?</h2>
                    <p className="text-gray-700 mb-4">
                        Cookies are small text files stored on your device when you visit a website. They help remember your preferences and improve website functionality.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">2. How do we use cookies?</h2>
                    <p className="text-gray-700 mb-4">
                        We use cookies to analyze site traffic, personalize content, and enhance user experience.
                    </p>
                    <h2 className="text-2xl font-semibold text-gray-800 mt-6 mb-4">3. Types of cookies used</h2>
                    <ul className="list-inside list-disc text-gray-700 mb-4">
                        <li><strong>Essential Cookies:</strong> Required for basic site functionality and security. These cannot be disabled.</li>
                        <li><strong>Analytics Cookies:</strong> Help us understand how visitors interact with our website, helping us improve our services.</li>
                        <li><strong>Marketing Cookies:</strong> Allow us to deliver personalized ads and content based on your browsing behavior.</li>
                        <li><strong>Preference Cookies:</strong> Enable the website to remember your preferences and customize your experience.</li>
                    </ul>
                </div>
            )}
        </div>
    );
};

export default CookieConditionsPage;
