import React, { useState, useEffect } from 'react';
import { X, Cookie, ChevronRight, ExternalLink } from 'lucide-react';
import labels from "./labels";

const STORAGE_KEY = 'cookie-preferences';

const CookieBanner = () => {
    const [isVisible, setIsVisible] = useState(false);
    const [showDetails, setShowDetails] = useState(false);
    const [showCustomize, setShowCustomize] = useState(false);
    const [language, setLanguage] = useState('en');

    const [currentLabels, setCurrentLabels] = useState(labels[language]);

    useEffect(() => {
        // Retrieve language from localStorage or set based on navigator.language
        const savedLanguage = localStorage.getItem('language');
        const browserLanguage = navigator.language.startsWith('ro') ? 'ro' : 'en';
        const initialLanguage = savedLanguage || browserLanguage;
        setCurrentLabels(labels[initialLanguage]);
    }, []);

    const handleLanguageChange = (event) => {
        const newLanguage = event.target.value;
        setCurrentLabels(labels[newLanguage]);
        localStorage.setItem('language', newLanguage);
        window.location.reload(); // Ricarica la pagina dopo il cambio lingua
    };

    const [preferences, setPreferences] = useState({
        essential: true,
        analytics: true,
        marketing: true,
        preferences: true
    });

    // Check localStorage on component mount
    useEffect(() => {
        const savedPreferences = localStorage.getItem(STORAGE_KEY);
        if (!savedPreferences) {
            setIsVisible(true);
        } else {
            setPreferences(JSON.parse(savedPreferences));
        }
    }, []);

    const saveToLocalStorage = (prefs) => {
        localStorage.setItem(STORAGE_KEY, JSON.stringify(prefs));
    };

    const handleAcceptAll = () => {
        const allAccepted = {
            essential: true,
            analytics: true,
            marketing: true,
            preferences: true
        };
        setPreferences(allAccepted);
        saveToLocalStorage(allAccepted);
        setIsVisible(false);
    };

    const handleSavePreferences = () => {
        saveToLocalStorage(preferences);
        setIsVisible(false);
    };

    const togglePreference = (key) => {
        setPreferences(prev => ({
            ...prev,
            [key]: !prev[key]
        }));
    };

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 shadow-lg p-4 md:p-6 z-50">
            {/* Rest of the component remains the same */}
            <div className="max-w-7xl mx-auto">
                <div className="flex flex-col gap-4">
                    <div className="flex items-start gap-4">
                        <div className="hidden md:block text-blue-600">
                            <Cookie size={24}/>
                        </div>

                        <div className="flex-1">
                            <h2 className="text-lg font-semibold text-gray-900 mb-2">
                                {currentLabels.lang === "en" ? "We value your privacy" : "Noi prețuim confidențialitatea dvs."}
                            </h2>
                            <div className="text-gray-600 text-sm md:text-base leading-relaxed space-y-2">
                                <p>
                                    {currentLabels.lang === "en"
                                        ? "We use cookies and similar technologies to help personalize content, tailor and measure ads, and provide a better experience. By clicking \"Accept all\", you consent to our use of cookies."
                                        : "Folosim cookie-uri și tehnologii similare pentru a personaliza conținutul, a adapta și a măsura reclamele și a oferi o experiență mai bună. Făcând clic pe \"Acceptă toate\", vă dați consimțământul pentru utilizarea cookie-urilor de către noi."}
                                </p>
                                <p className="flex items-center gap-1">
                                    {currentLabels.lang === "en" ? "For more information, please read our" : "Pentru mai multe informații, citiți"} {' '}
                                    <a href="/cookie-policy"
                                       className="text-blue-600 hover:text-blue-700 inline-flex items-center gap-1 underline">
                                        {currentLabels.lang === "en" ? "Cookie Policy" : "Politica privind cookie-urile"}
                                        <ExternalLink size={14}/>
                                    </a>
                                </p>
                            </div>

                            {!showCustomize && (
                                <button
                                    onClick={() => setShowDetails(!showDetails)}
                                    className="flex items-center text-blue-600 hover:text-blue-700 text-sm mt-2 font-medium"
                                >
                                    {currentLabels.lang === "en" ? "Learn more about our cookies" : "Aflați mai multe despre cookie-urile noastre"}
                                    <ChevronRight size={16}
                                                  className={`ml-1 transform transition-transform ${showDetails ? 'rotate-90' : ''}`}/>
                                </button>
                            )}

                            {showDetails && !showCustomize && (
                                <div className="mt-4 bg-gray-50 p-4 rounded-lg text-sm text-gray-600">
                                    <p className="mb-3">
                                        <strong>{currentLabels.lang === "en" ? "Essential Cookies:" : "Cookie-uri esențiale:"}</strong> {currentLabels.lang === "en" ? "Required for basic site functionality and security. These cannot be disabled." : "Necesare pentru funcționalitatea de bază a site-ului și securitate. Acestea nu pot fi dezactivate."}
                                    </p>
                                    <p className="mb-3">
                                        <strong>{currentLabels.lang === "en" ? "Analytics Cookies:" : "Cookie-uri de analiză:"}</strong> {currentLabels.lang === "en" ? "Help us understand how visitors interact with our website, helping us improve our services." : "Ne ajută să înțelegem cum interacționează vizitatorii cu site-ul nostru, ajutându-ne să îmbunătățim serviciile noastre."}
                                    </p>
                                    <p className="mb-3">
                                        <strong>{currentLabels.lang === "en" ? "Marketing Cookies:" : "Cookie-uri de marketing:"}</strong> {currentLabels.lang === "en" ? "Allow us to deliver personalized ads and content based on your browsing behavior." : "Ne permit să oferim reclame și conținut personalizat pe baza comportamentului dvs. de navigare."}
                                    </p>
                                    <p>
                                        <strong>{currentLabels.lang === "en" ? "Preference Cookies:" : "Cookie-uri de preferință:"}</strong> {currentLabels.lang === "en" ? "Enable the website to remember your preferences and customize your experience." : "Permit site-ului să vă rețină preferințele și să vă personalizeze experiența."}
                                    </p>
                                </div>
                            )}

                            {showCustomize && (
                                <div className="mt-4 space-y-4">
                                    {['essential', 'analytics', 'marketing', 'preferences'].map((type) => (
                                        <div key={type}
                                             className="flex items-center justify-between p-3 bg-gray-50 rounded-lg">
                                            <div>
                                                <h3 className="font-medium text-gray-900">
                                                    {currentLabels.lang === "en" ? `${type.charAt(0).toUpperCase() + type.slice(1)} Cookies` :
                                                        type === 'essential' ? "Cookie-uri esențiale" :
                                                            type === 'analytics' ? "Cookie-uri de analiză" :
                                                                type === 'marketing' ? "Cookie-uri de marketing" : "Cookie-uri de preferință"}
                                                </h3>
                                                <p className="text-sm text-gray-500">
                                                    {currentLabels.lang === "en" ?
                                                        (type === 'essential' ? "Required for basic site functionality" :
                                                            type === 'analytics' ? "Help us improve our services" :
                                                                type === 'marketing' ? "Enable personalized content and ads" : "Remember your settings") :
                                                        (type === 'essential' ? "Necesare pentru funcționalitatea de bază a site-ului" :
                                                            type === 'analytics' ? "Ne ajută să îmbunătățim serviciile noastre" :
                                                                type === 'marketing' ? "Permit conținut și reclame personalizate" : "Memorează setările dvs.")}
                                                </p>
                                            </div>
                                            <button
                                                onClick={() => type !== 'essential' && togglePreference(type)}
                                                className="relative inline-flex h-6 w-11 items-center rounded-full bg-gray-200"
                                                disabled={type === 'essential'}
                                            >
                                                <span
                                                    className={`${preferences[type] ? 'translate-x-6 bg-blue-600' : 'translate-x-1 bg-gray-400'} inline-block h-4 w-4 transform rounded-full transition`}/>
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>

                    </div>

                    <div className="flex flex-col md:flex-row gap-3 items-center justify-end border-t pt-4">
                        {!showCustomize ? (
                            <>
                                <button
                                    onClick={() => setShowCustomize(true)}
                                    className="px-6 py-2.5 w-full md:w-auto bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg text-sm font-medium transition-colors order-2 md:order-1"
                                >
                                    {currentLabels.lang === "en" ? "Customize preferences" : "Personaliza preferințele"}
                                </button>
                                <button
                                    onClick={handleAcceptAll}
                                    className="px-6 py-2.5 w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm font-medium transition-colors order-1 md:order-2"
                                >
                                    {currentLabels.lang === "en" ? "Accept all" : "Acceptă toate"}
                                </button>

                            </>
                        ) : (
                            <>
                            <button
                                    onClick={() => setShowCustomize(false)}
                                    className="px-6 py-2.5 w-full md:w-auto bg-gray-100 hover:bg-gray-200 text-gray-700 rounded-lg text-sm font-medium transition-colors order-2 md:order-1"
                                >
                                    Back
                                </button>
                                <button
                                    onClick={handleSavePreferences}
                                    className="px-6 py-2.5 w-full md:w-auto bg-blue-600 hover:bg-blue-700 text-white rounded-lg text-sm font-medium transition-colors order-1 md:order-2"
                                >
                                    Save preferences
                                </button>
                            </>
                        )}

                        <button
                            onClick={() => setIsVisible(false)}
                            className="absolute top-4 right-4 p-2 text-gray-400 hover:text-gray-600 rounded-full transition-colors md:hidden"
                        >
                            <X size={20}/>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CookieBanner;